import ApiPropertyService from './ApiPropertyService'
import type {
  AddCategory,
  AddDigitalKey,
  AddExtras,
  AddFloor,
  AddPromotionType,
  AddRestrictionPlanType,
  AddRoom,
  AddRoomNumber,
  AddSpecialOffers,
  AddUsers,
  EditBackAccountType,
  EditCanncellationType,
  EditCategory,
  EditDigitalKey,
  EditExtras,
  EditFloor,
  EditPromotionType,
  EditRestrictionPlanType,
  EditSpecialOffers,
  EditUsers
} from '@/@types/property'

import dayjs from 'dayjs'

export function apiAddProperty(data: any) {
  let rawData = JSON.stringify({
    property: {
      business_name: data.business_name,
      number_of_units: data?.number_of_units,
      number_of_floor: data?.number_of_floor,
      property_type: data?.property_type
    },
    address: {
      address: data?.address,
      country: data?.country,
      city: data?.city,
      zip_code: data?.zip_code,
      longitude: data?.longitude,
      latitude: data?.latitude
    }
  })

  return ApiPropertyService.fetchData({
    url: 'property/add-property/',
    method: 'post',
    data: rawData,
    token: data?.token,
    content_type: true
  })
}

export function apiFetchPricingPlan(data: any) {
  return ApiPropertyService.fetchData({
    url: `policy/get-price-policy/?property_id=${data?.id}`,
    method: 'get',
    token: data?.token,
    content_type: true
  })
}

export function apiFetchUnitPrice(data: {
  pricingPlanId?: number
  propertyId: number
  unitId?: number
  startDate?: string
  endDate?: string
  date?: string
  token?: string
}) {
  const planId = data?.pricingPlanId
    ? `&pricing_plan_id=${data?.pricingPlanId}`
    : ''
  const endpoint = `/policy/get-unit-price/?property_id=${data?.propertyId}${planId}`
  const dateRange =
    data?.startDate && data?.endDate
      ? `&start_date=${data?.startDate}&end_date=${data?.endDate}`
      : ''
  const date = data?.date ? `&date=${data?.date}` : ''
  const unit = data?.unitId ? `&unit_id=${data?.unitId}` : ''

  const url = endpoint + unit + (dateRange || date)

  return ApiPropertyService.fetchData({
    url,
    method: 'get',
    ...(data?.token ? { token: data?.token } : {}),
    content_type: true
  })
}

// apiSkipProperty

export function apiSkipProperty(data: any) {
  let formdata: any = new FormData()
  formdata.append('property_id', data?.property_id)
  formdata.append('skip', data?.skip)
  return ApiPropertyService.fetchData({
    url: 'property/skip-unit/',
    method: 'post',
    data: formdata,
    token: data?.token,
    content_type: true
  })
}

// apiEditProperty

export function apiAddUnit(data: any) {
  return ApiPropertyService.fetchData({
    url: 'room/add-unit-info/',
    method: 'post',
    data: JSON.stringify(data?.payload),
    token: data?.token,
    content_type: true
  })
}

// apiAddUnitImage

export function apiAddUnitImage(data: any) {
  const formData = new FormData()
  formData.append('unit_info', data?.unit_info)

  if (data?.images?.length > 0) {
    for (const img of data.images) {
      img && formData.append('image', img)
    }
  }

  return ApiPropertyService.fetchData({
    url: 'room/add-unit-image/',
    method: 'post',
    data: formData,
    token: data?.token
  })
}

export const apiDeleteUnitImage = (data: { token: string; id: number }) =>
  ApiPropertyService.fetchData({
    url: `room/delete-unit-image/${data?.id}`,
    method: 'delete',
    token: data?.token
  })

export function apiEditProperty(data: any) {
  const formdata = new FormData()

  formdata.append('channex_property_id', data?.channex_property_id)
  formdata.append('business_name', data?.property_name)
  data?.property_type && formdata.append('property_type', data?.property_type)
  data?.property_email &&
    formdata.append('property_email', data?.property_email)
  data?.registration_number &&
    formdata.append('registration_number', data?.registration_number)
  data?.tin_number && formdata.append('tin_number', data?.tin_number)
  data?.property_email &&
    formdata.append('property_email', data?.property_email)
  formdata.append('in_vat_system', data?.in_vat_system)
  data?.in_vat_system &&
    data?.vat_number &&
    formdata.append('vat_number', data?.vat_number)
  data?.logo && formdata.append('logo', data?.logo)
  data?.date_format && formdata.append('date_format', data?.date_format)
  data?.send_email_for_new_reservation &&
    formdata.append(
      'send_email_for_new_reservation',
      data?.send_email_for_new_reservation
    )
  data?.currency && formdata.append('currency', data?.currency)
  data?.company_name && formdata.append('company_name', data?.company_name)

  return ApiPropertyService.fetchData({
    url: `property/update-property/${data.id}/`,
    method: 'put',
    data: formdata,
    token: data?.token
  })
}

export const apiEditPropertyPostalAddress = (data: any) => {
  let formdata = new FormData()
  {
    data?.country && formdata.append('country', data?.country)
  }
  {
    data?.address && formdata.append('address', data?.address)
  }

  {
    data?.city && formdata.append('city', data?.city)
  }
  return ApiPropertyService.fetchData({
    url: `lookups/update-address/${data.id}/`,
    method: 'put',
    data: formdata,
    token: data?.token
  })
}

export const apiEditCurrency = (data: any) => {
  let formdata = new FormData()
  formdata.append(
    'currency_channel_manager',
    data?.data?.currency_channel_manager
  )
  formdata.append(
    'channel_manager_current_rate',
    data?.data?.channel_manager_currency_rate
  )
  formdata.append('extra_currency', data?.data?.extra_currency)
  formdata.append('extra_currency_rate', data?.data?.extra_currency_rate)
  formdata.append('invoice_additional_currency', data?.data?.invoice_currency)
  formdata.append(
    'invoice_additional_currency_rate',
    data?.data?.invoice_additional_currency_rate
  )
  return ApiPropertyService.fetchData({
    url: `property/update-currency-setting/${data.id}/`,
    method: 'put',
    data: formdata,
    token: data?.token
  })
}

// apiEditPropertyPolicy

export const apiEditPropertyPolicy = (data: any) => {
  const formdata = new FormData()

  const { payload, id, token } = data

  for (const [key, value] of Object.entries(payload)) {
    formdata.append(key, value as any)
  }

  return ApiPropertyService.fetchData({
    url: `policy/update-property-policy/${id}/`,
    method: 'put',
    data: formdata,
    token: token
  })
}

export const apiEditInvoice = (data: any) => {
  let formdata = new FormData()
  formdata.append('date_of_turnover', data?.data?.date_of_turnover)
  formdata.append('issuing_address', data?.data?.issuing_address)
  formdata.append('separate_check_number', data?.data?.separate_check_number)
  formdata.append('show_invoice_service_room', data?.data?.show_inv_services)
  formdata.append('group_room_by_price', data?.data?.group_room_by_price)
  formdata.append('group_pose_service', data?.data?.group_pos_service)
  formdata.append(
    'guest_name_to_invoice_note',
    data?.data?.add_guest_names_to_the_invoice_note_section
  )
  formdata.append(
    'guest_checkout_only',
    data?.data?.allow_guest_check_out_only_if_invoices_are
  )
  formdata.append('show_id_reservation', data?.data?.show_id_reservation)
  {
    data?.data?.invoice_setting_file &&
      formdata.append('invoice_setting_file', data?.data?.invoice_setting_file)
  }
  return ApiPropertyService.fetchData({
    url: `property/update-invoice-setting/${data.id}/`,
    method: 'put',
    data: formdata,
    token: data?.token
  })
}
//=========================api add rooms========================
export const apiAddRooms = (data: AddRoom) => {
  const mapAmenties = data?.amenities?.map((am: any) => am.value)

  const formData = new FormData()
  formData.append(
    'unit_info',
    JSON.stringify({
      accommodation_unit_type_name: data.name,
      short_name: data.short_name,
      number_of_units: data.availability,
      standard_price: data.standard_price,
      guest_number: data.occupancy,
      area: data.area,
      number_of_bathroom: data.number_of_bathrooms,
      bed_type: data.bed_type,
      number_of_houseroom: 1,
      amenities: mapAmenties,
      linked_room_type: false,
      price_per_person: data.price_per_person,
      occupancy: data.occupancy,
      property_id: data.property_id
    })
  )

  return ApiPropertyService.fetchData({
    url: 'room/add-unit-info/',
    method: 'post',
    data: formData,
    token: data?.token,
    content_type: true
  })
}
//=========================api add room numbers========================
export const apiAddRoomNumber = (data: AddRoomNumber) => {
  const beds: any = []
  const entries = Object.entries(data?.number_of_bed_type)

  for (const [_key, val] of entries) {
    beds.push({
      bed_type: (val as any)?.bed_type
    })
  }
  let rawData = JSON.stringify({
    room_number: data?.room_number,
    unit_info: data?.unit_type,
    houseroom_type: data?.room_type,
    number_of_beds: data?.number_of_beds,
    bed_info: beds
  })

  return ApiPropertyService.fetchData({
    url: 'room/add-room-number/',
    method: 'post',
    data: rawData,
    token: data?.token,
    content_type: true
  })
}
//=========================api add room numbers========================
export const apiEditRoomNumber = (data: AddRoomNumber) => {
  const beds: any = []

  if (data?.field) {
    const entries = Object.entries(data?.field)
    for (const [_key, val] of entries) {
      beds.push({
        ...((val as any)?.id && {
          id: (val as any)?.id
        }),
        ...((val as any)?.bed_type && {
          bed_type: (val as any)?.bed_type
        })
      })
    }
  }

  const rawData = JSON.stringify({
    ...(data?.room_number && {
      room_number: data?.room_number
    }),
    ...{
      room_status: data?.room_status
    },
    unit_info: data?.unit_type,
    ...(data?.room_type && {
      houseroom_type: data?.room_type
    }),
    number_of_beds: data?.number_of_beds || 0,
    ...(beds?.length > 0 && {
      bed_info: beds
    })
  })

  return ApiPropertyService.fetchData({
    url: `room/update-room-number/${data?.recordId}/`,
    method: 'put',
    data: rawData,
    token: data?.token,
    content_type: true
  })
}
//====================api edit room===========================
export const apiEditRooms = (data: any) => {
  return ApiPropertyService.fetchData({
    url: `room/update-unit-info/${data.recordId}/`,
    method: 'put',
    data: JSON.stringify(data?.payload),
    token: data?.token,
    content_type: true
  })
}
//==================api add floor======================
export const apiAddFloor = (data: AddFloor) => {
  const roomNumber = data?.room_number?.map((room: any) => room.value)
  let rawData = JSON.stringify({
    floor_name: data.floor,
    property_id: data.property_id,
    rooms_number: roomNumber
  })
  return ApiPropertyService.fetchData({
    url: 'room/add-floor/',
    method: 'post',
    data: rawData,
    token: data?.token,
    content_type: true
  })
}
//====================api edit floor========================
export const apiEditFloor = (data: EditFloor) => {
  const roomNumber = data?.room_number?.map((room: any) => room.value)

  let rawData = JSON.stringify({
    floor_name: data.floor,
    rooms_number: roomNumber
  })

  return ApiPropertyService.fetchData({
    url: `room/update-floor/${data?.floorRecordId}/`,
    method: 'put',
    data: rawData,
    token: data?.token,
    content_type: true
  })
}
// ============== add users ======================

export const apiAddUsers = (data: AddUsers) => {
  const propertyValues = data?.properties.map((pro: any) => pro?.value) || []

  const joinProperty = propertyValues?.join(',')

  const propertiesDataArray = propertyValues.map((propertyValue: any) => ({
    ...(propertyValue && {
      property_id: propertyValue
    }),

    room_type: data?.room_type
      .filter((room: any) => room.property_id === propertyValue)
      .map((r: any) => r.value),

    room_number: data?.room_numbers
      ?.filter((room: any) => room.property_id === propertyValue)
      .map((rn: any) => rn.value)
  }))

  const rawData = JSON.stringify({
    user_data: {
      fullname: data.fullname,
      email: data.email,
      send_invitation_email: data.user_without_email,
      property_id: joinProperty
    },
    properties_data: propertiesDataArray
  })
  return ApiPropertyService.fetchData({
    url: 'property/add-user/',
    method: 'post',
    data: rawData,
    token: data.token,
    content_type: true
  })
}
// ============== edit users ======================

export const apiEditUsers = (data: EditUsers) => {
  const oldProperty = data?.prevProperties?.filter((p: any) => {
    return data.properties.some((pp: any) => pp.value === p.property_id.id)
  })

  const newProperty = data?.properties?.filter((pro: any) => {
    return !data.prevProperties.some(
      (pp: any) => pp.property_id.id === pro.value
    )
  })
  const oldPropertiesDataArray = oldProperty.map((propertyValue: any) => ({
    ...(propertyValue && {
      id: propertyValue?.id
    }),

    room_type: data?.room_type
      .filter((room: any) => room.property_id === propertyValue?.property_id.id)
      .map((r: any) => r.value),

    room_number: data?.room_numbers
      ?.filter(
        (room: any) =>
          room.property_id === propertyValue?.property_id.id &&
          data?.room_type.some((rm: any) => rm.value === room.room_type_id)
      )
      .map((rn: any) => rn.value)
  }))

  const newPropertiesDataArray = newProperty.map((propertyValue: any) => ({
    ...(propertyValue && {
      property_id: propertyValue?.value
    }),

    ...(data?.account && {
      account: data?.account
    }),

    room_type: data?.room_type
      .filter((room: any) => room.property_id === propertyValue?.value)
      .map((r: any) => r.value),

    room_number: data?.room_numbers
      ?.filter(
        (room: any) =>
          room.property_id === propertyValue?.value &&
          data?.room_type.some((rm: any) => rm.value === room.room_type_id)
      )
      .map((rn: any) => rn.value)
  }))

  let rawData = {
    user_data: {
      id: data?.id,
      fullname: data?.fullname
    },
    properties_data: oldPropertiesDataArray,
    ...(newPropertiesDataArray?.length > 0 && {
      new_properties: newPropertiesDataArray
    })
  }

  return ApiPropertyService.fetchData({
    url: 'property/update-user/',
    method: 'put',
    data: rawData,
    token: data.token,
    content_type: true
  })
}

// ============= api User Property ====================
export const apiDeleteUserProperty = (data: { id: number; token: string }) => {
  return ApiPropertyService.fetchData({
    url: `property/remove-property/?ids=${data.id}`,
    method: 'delete',
    token: data.token
  })
}

// ============== edit bank account ======================

export const apiEditbankSetting = (data: EditBackAccountType) => {
  let formdata = new FormData()
  formdata.append('TIN', data.tin)
  formdata.append('IBAN', data.iban)
  formdata.append('swift', data.swift)
  formdata.append('bank_account', data.account_1)
  formdata.append('bank_account_2', data.account_2)
  return ApiPropertyService.fetchData({
    url: `property/update-bank-setting/${data.id}/`,
    method: 'put',
    data: formdata,
    token: data.token
  })
}

//=============== api Add Restriction Plan=============

export const apiAddRestrictionPlan = (data: AddRestrictionPlanType) => {
  let formdata: any = new FormData()
  formdata.append('property_id', data.id)
  formdata.append('policy_name', data?.name)
  formdata.append('policy_type', data?.type)
  formdata.append('minimum_stay', data?.minStay)
  formdata.append('maximum_stay', data?.maxStay)
  formdata.append('arrival', data?.arrival)
  formdata.append('closed', data?.closed)
  formdata.append('departure', data?.departure)
  return ApiPropertyService.fetchData({
    url: 'policy/add-restriction-policy/',
    method: 'post',
    data: formdata,
    token: data.token
  })
}

//=============== api Edit Restriction Plan=============
export const apiEditRestrictionPlan = (data: EditRestrictionPlanType) => {
  let formdata: any = new FormData()
  formdata.append('policy_name', data?.name)
  formdata.append('policy_type', data?.type)
  formdata.append('minimum_stay', data?.minStay)
  formdata.append('maximum_stay', data?.maxStay)
  formdata.append('arrival', data?.arrival)
  formdata.append('closed', data?.closed)
  formdata.append('departure', data?.departure)
  return ApiPropertyService.fetchData({
    url: `policy/update-restriction-policy/${data?.dataId}/`,
    method: 'put',
    data: formdata,
    token: data.token
  })
}

//=============== api Edit Restriction Plan=============
export const apiEditDefaultRestrictionPlan = (data: any) => {
  let formdata: any = new FormData()
  formdata.append('default_restriction_plans', data?.defaultValue)
  return ApiPropertyService.fetchData({
    url: `policy/update-restriction-policy/${data?.record_id}/`,
    method: 'put',
    data: formdata,
    token: data.token
  })
}

// ==============apiAddCancellation================================
export const apiAddCancellation = (data: {
  free_cancellation: boolean | undefined
  day_for_free_cancellation: number | undefined
  customFields: any
  name: string | undefined
  description: string | undefined
  id: any
  type: string | undefined
  payment_amount: number | undefined
  token: any
}) => {
  const custom: any = []
  const entries = Object.entries(data?.customFields)
  for (const [_key, val] of entries) {
    custom.push({
      days_before_arrival: (val as any)?.label1,
      price_in_percent: (val as any)?.label2
    })
  }
  let rawData = JSON.stringify({
    property_id: data?.id,
    policy_name: data?.name,
    ...(data?.free_cancellation && {
      free_cancellation: data?.free_cancellation
    }),
    ...(data?.type && {
      policy_type: data?.type
    }),
    ...(data?.payment_amount && {
      payment_amount_in_percent: data?.payment_amount
    }),
    day_for_free_cancellation: data?.day_for_free_cancellation,
    ...(data.description && {
      description: data.description
    }),
    ...(data?.type === 'CUSTOM' && {
      custom_data: custom
    })
  })
  return ApiPropertyService.fetchData({
    url: 'policy/add-cancellation-policy/',
    method: 'post',
    data: rawData,
    token: data.token,
    content_type: true
  })
}
// ==============apiAddCategory================================
export const apiAddCategory = (data: AddCategory) => {
  let formdata = new FormData()
  formdata.append('category_name', data?.name)
  formdata.append('show_on_guest_app', data?.huest_app_home_screen)
  formdata.append('property_id', data.property)
  return ApiPropertyService.fetchData({
    url: 'room/add-category/',
    method: 'post',
    data: formdata,
    token: data.token
  })
}
// ==============apiEditCategory================================
export const apiEditCategory = (data: EditCategory) => {
  let formdata = new FormData()
  formdata.append('category_name', data?.name)
  formdata.append('show_on_guest_app', data?.huest_app_home_screen)
  formdata.append('property_id', data.property)
  return ApiPropertyService.fetchData({
    url: `room/update-category/${data?.categoryRecordId}/`,
    method: 'put',
    data: formdata,
    token: data?.token
  })
}

// ==========apiEditCancellation===================
export const apiEditCancellation = (data: EditCanncellationType) => {
  const custom: any = []
  const entries = Object.entries(data?.customFields)
  for (const [_key, val] of entries) {
    custom.push({
      ...((val as any)?.recordId && {
        id: (val as any)?.recordId
      }),
      days_before_arrival: (val as any)?.label1,
      price_in_percent: (val as any)?.label2
    })
  }
  let rawData = JSON.stringify({
    property_id: data?.id,
    policy_name: data?.name,
    ...(data?.free_cancellation && {
      free_cancellation: data?.free_cancellation
    }),
    ...(data?.type && {
      policy_type: data?.type
    }),
    payment_amount_in_percent: data?.payment_amount_in_percent,
    day_for_free_cancellation: data?.day_for_free_cancellation,
    ...(data.description && {
      description: data.description
    }),
    ...(data?.type === 'CUSTOM' && {
      custom_data: custom
    })
  })
  return ApiPropertyService.fetchData({
    url: `policy/update-cancellation-policy/${data?.record_id}/`,
    method: 'put',
    data: rawData,
    token: data.token,
    content_type: true
  })
}

// ==========apiAddpromotion===================
export const apiAddpromotion = (data: AddPromotionType) => {
  const mapRoom = data?.roomsSelect?.map((room: any) => room?.value)

  const mapPrice = data?.priceSelect?.map((price: any) => price?.value)
  const rawData = JSON.stringify({
    name: data?.name,
    // code: '',
    pricing_plan_type: data?.pricingPlanType,
    ...(data?.pricingPlanType === 'Custom' && {
      pricing_plan: mapPrice
    }),
    room_type: data?.roomType,
    ...(data?.roomType === 'Custom' && {
      room: mapRoom
    }),
    // amount: '',
    discount: data?.discounts,
    min_guest_stay: data?.minGuestsStay,
    stay_date_from: data?.stayFrom,
    stay_date_to: data?.stayTo,
    not_stackable_with_other_discount: false,
    activate_promotion: data?.activationPeriod,
    monday: data?.monday,
    tuesday: data?.tuesday,
    wednesday: data?.wednesday,
    thursday: data?.thursday,
    friday: data?.friday,
    saturday: data?.saturday,
    sunday: data?.sunday,
    limit_bookable_period: data?.limitBookableMaterial,
    bookable_from: data?.bookableFrom,
    bookable_to: data?.bookableTo,
    description: data.text_area,
    property_id: data?.property_id,
    promotion_type: data?.type,
    ...(data?.pricingPlanType ===
      'Last-Minute Deal (Fill any empty rooms you have left)' && {
      before_check_in: data?.before_check_in
    }),
    ...(data?.pricingPlanType ===
      'Last-Minute Deal (Fill any empty rooms you have left)' && {
      before_check_in_type: data?.before_check_in_type
    }),
    ...(data?.pricingPlanType ===
      'Early Booker Deal (Be better prepared with more of your bookings made earlier)' && {
      days_or_more_before_check_in: data?.before_check_in
    }),
    ...(data?.pricingPlanType ===
      'Early Booker Deal (Be better prepared with more of your bookings made earlier)' && {
      before_check_in_type: 'Days'
    })
  })
  return ApiPropertyService.fetchData({
    url: 'property/create-promotion/',
    method: 'post',
    data: rawData,
    token: data.token,
    content_type: true
  })
}

//apiEditPromotion

// ==========apiEditPromotion===================
export const apiEditPromotion = (data: EditPromotionType) => {
  const mapRoom = data?.roomsSelect?.map((room: any) => room?.value)

  const mapPrice = data?.priceSelect?.map((price: any) => price?.value)
  const rawData = JSON.stringify({
    name: data?.name,
    // code: '',
    ...(data?.pricingPlanType && {
      pricing_plan_type: data?.pricingPlanType
    }),
    ...(data?.pricingPlanType === 'Custom' && {
      pricing_plan: mapPrice
    }),
    room_type: data?.roomType,
    ...(data?.roomType === 'Custom' && {
      room: mapRoom
    }),
    discount: data?.discounts,
    min_guest_stay: data?.minGuestsStay,
    stay_date_from: data?.stayFrom,
    stay_date_to: data?.stayTo,
    not_stackable_with_other_discount: false,
    activate_promotion: data?.activationPeriod,
    monday: data?.monday,
    tuesday: data?.tuesday,
    wednesday: data?.wednesday,
    thursday: data?.thursday,
    friday: data?.friday,
    saturday: data?.saturday,
    sunday: data?.sunday,
    limit_bookable_period: data?.limitBookableMaterial,
    bookable_from: data?.bookableFrom,
    bookable_to: data?.bookableTo,
    description: data.text_area,
    property_id: data?.property_id,
    promotion_type: data?.type,
    ...(data?.pricingPlanType ===
      'Last-Minute Deal (Fill any empty rooms you have left)' && {
      before_check_in: data?.before_check_in
    }),
    ...(data?.pricingPlanType ===
      'Last-Minute Deal (Fill any empty rooms you have left)' && {
      before_check_in_type: data?.before_check_in_type
    }),
    ...(data?.pricingPlanType ===
      'Early Booker Deal (Be better prepared with more of your bookings made earlier)' && {
      days_or_more_before_check_in: data?.before_check_in
    }),
    ...(data?.pricingPlanType ===
      'Early Booker Deal (Be better prepared with more of your bookings made earlier)' && {
      before_check_in_type: 'Days'
    })
  })
  return ApiPropertyService.fetchData({
    url: `property/update-promotion/${data?.record_id}/`,
    method: 'put',
    data: rawData,
    token: data.token,
    content_type: true
  })
}

// ==========apiEditPromotion===================
export const apiAddSpecialOffer = (data: AddSpecialOffers) => {
  const roomsIds = data?.rooms?.map((room: any) => room.value)
  const pricePerDayArray: any = []
  const entries = Object.entries(data?.selectedRoomInputData)

  for (const [, val] of entries) {
    pricePerDayArray.push({
      unit_info: (val as any)?.id,
      price: (val as any)?.value ? (val as any)?.value : 0
    })
  }

  const rawData = JSON.stringify({
    name: data?.name,
    min_night: data?.min_night,
    max_night: data?.max_night,
    adults: data?.adult,
    children: data?.children,
    room: roomsIds,
    period_by_restriction_plan: data?.period_by_restriction,
    description: data?.description,
    property_id: data?.property_id,
    bookable_from: data?.bookable_from,
    bookable_to: data?.bookable_to,
    boards_type: data?.board,
    stay_from: data?.stay_from,
    stay_to: data?.stay_to,
    price_policy: data?.policies,
    price_per_day: pricePerDayArray,
    show_on_first_step: data?.show_on_first_step
  })

  return ApiPropertyService.fetchData({
    url: 'room/add-special-offer/',
    method: 'post',
    data: rawData,
    token: data.token,
    content_type: true
  })
}

// apiEidtSpecialOffer

// ==========apiEidtSpecialOffer===================
export const apiEidtSpecialOffer = (data: EditSpecialOffers) => {
  const roomsIds = data?.rooms.map((room: any) => room.value)
  const pricePerDayArray: any = []

  const entries = Object.entries(data?.selectedRoomInputData)
  for (let [_key, val] of entries) {
    pricePerDayArray.push({
      ...((val as any)?.recordId && {
        id: (val as any)?.recordId
      }),
      ...((val as any)?.id && {
        unit_info: (val as any)?.id
      }),
      price: (val as any)?.value
    })
  }
  const rawData = JSON.stringify({
    name: data?.name,
    min_night: data?.min_night,
    max_night: data?.max_night,
    adults: data?.adult,
    children: data?.children,
    room: roomsIds,
    period_by_restriction_plan: data?.period_by_restriction,
    description: data?.description,
    property_id: data?.property_id,
    bookable_from: data?.bookable_from,
    bookable_to: data?.bookable_to,
    stay_from: data?.stay_from,
    stay_to: data?.stay_to,
    price_policy: data?.policies,
    price_per_day: pricePerDayArray
  })

  return ApiPropertyService.fetchData({
    url: `room/update-special-offer/${data?.record_id}/`,
    method: 'put',
    data: rawData,
    token: data.token,
    content_type: true
  })
}
// ==========apiAddExtras===================
export const apiAddExtras = (data: AddExtras) => {
  const rawData = {
    extra_name: data?.name,
    price: data?.price,
    tax: data?.tax,
    date_to: data?.to_date,
    date_from: data?.from_date,
    description: data?.description,
    extra_type: data?.type,
    category: data?.extras_category,
    use_on_booking_engine: data?.use_on_booking_engine,
    pre_defined_extras: data?.pre_defined_extras,
    period_by_restriction_plan: data?.period_by_restriction_plan,
    included_in_room: data?.included_in_rooms?.map(
      (inclRoomm: any) => inclRoomm.value
    ),
    only_for_room: data?.only_for_rooms?.map(
      (onlyForRoomm: any) => onlyForRoomm.value
    ),
    mandatory_for_rooms: data?.mondatory_in_room?.map(
      (mondatoryRoomm: any) => mondatoryRoomm.value
    ),

    property_id: data?.propertyId
  }
  return ApiPropertyService.fetchData({
    url: 'room/add-extras/',
    method: 'post',
    data: JSON.stringify(rawData),
    token: data.token,
    content_type: true
  })
}

export const apiEditExtras = (data: EditExtras) => {
  const rawData = {
    extra_name: data?.name,
    price: data?.price,
    tax: data?.tax,
    date_to: data?.to_date,
    date_from: data?.from_date,
    description: data?.description,
    extra_type: data?.type,
    category: data?.extras_category,
    use_on_booking_engine: data?.use_on_booking_engine,
    pre_defined_extras: data?.pre_defined_extras,
    period_by_restriction_plan: data?.period_by_restriction_plan,
    included_in_room: data?.included_in_rooms?.map(
      (inclRoomm: any) => inclRoomm.value
    ),
    only_for_room: data?.only_for_rooms?.map(
      (onlyForRoomm: any) => onlyForRoomm.value
    ),
    mandatory_for_rooms: data?.mondatory_in_room?.map(
      (mondatoryRoomm: any) => mondatoryRoomm.value
    ),

    property_id: data?.propertyId
  }
  return ApiPropertyService.fetchData({
    url: `room/update-extras/${data?.extrasId}/`,
    method: 'put',
    data: rawData,
    token: data.token,
    content_type: true
  })
}

// ========================apiAddPricingPlan=========================

export const apiAddPricingPlan = (data: any) => {
  let formdata: any = new FormData()
  console.log("values in apiAddPricingPlan", data)

  formdata.append(
    'price_plane',
    JSON.stringify({
      property_id: data?.property_id,
      policy_name: data?.name,
      use_on_booking_engine: data?.booking_engin,
      auto: data?.isAutoManual,
      ...(data?.copy_price_text && {
        copy_price_to_next_year: data?.copy_price_text
      }),
      ...(data?.overwrite_current_price && {
        overwrite_current_price: data?.overwrite_current_price
      }),
      price_per_person: data?.price_per_person,

      ...(data?.activate_minimum_booking_offset && {
        activate_minimum_booking_offset: data?.minBookingOffest
      }),
      ...(!data?.auto && {
        default_price_plane: data?.default_price
      }),
      variation_type: data?.variation_type,
      variation: data?.variation,
      cancellation_policy: data?.collection_policy,
      boards: data?.board,
      restriction_plan: data?.restriction_plan
    })
  )
  formdata.append(
    'person_occupancy',
    JSON.stringify([
      {
        adult_count: 1,
        children: 1,
        children_count: 1,
        amount: data?.children1_amount,
        variation: data?.children1_variation
      }
    ])
  )

  return ApiPropertyService.fetchData({
    url: 'policy/add-price-policy/',
    method: 'post',
    data: formdata,
    token: data.token
  })
}

// =======================apiAddUnitPrice===========================
export const apiAddUnitPrice = (data: any) => {
  return ApiPropertyService.fetchData({
    url: 'policy/add-unit-price/',
    method: 'post',
    data: JSON.stringify({
      unit_price: [...(data && data.data.unit_price)]
    }),
    token: data.token,
    content_type: true
  })
}

// =======================apiUpdateUnitPrice===========================
export const apiUpdateUnitPrice = (data?: any) =>
  ApiPropertyService.fetchData({
    url: 'policy/update-unit-price/',
    method: 'put',
    data: JSON.stringify({
      unit_price: [...(data && data.data.unit_price)],
      ...(data.data.days ? { days: [...(data && data.data.days)] } : {})
    }),
    token: data.token,
    content_type: true
  })

//======================== apiEditDigitalKey=========================
export const apiUploadCSV = (data: any) => {
  let formdata: any = new FormData()
  formdata.append('property_id', data?.property_id)
  formdata.append('file', data?.file[0])
  return ApiPropertyService.fetchData({
    url: 'room/upload-csv/',
    method: 'post',
    data: formdata,
    token: data?.token
  })
}
//======================== apiAddDigitalKey=========================
export const apiAddDigitalKey = (data: AddDigitalKey) => {
  let formdata: any = new FormData()
  formdata.append('property_id', data?.property_id)
  formdata.append('room_number', data?.room_number)
  formdata.append('NFC_key', data?.NFC_key)
  return ApiPropertyService.fetchData({
    url: 'room/add-room-digital-key/',
    method: 'post',
    data: formdata,
    token: data?.token
  })
}

//======================== apiEditDigitalKey=========================
export const apiEditDigitalKey = (data: EditDigitalKey) => {
  let formdata: any = new FormData()
  formdata.append('room_number', data?.room_number)
  return ApiPropertyService.fetchData({
    url: `room/update-room-digital-key/${data?.record_id}/`,
    method: 'put',
    data: formdata,
    token: data?.token,
    content_type: true
  })
}

//======================== apiEditPricingPlan=========================
export const apiEditPricingPlan = (data: any) => {
  let formdata: any = new FormData()
  formdata.append('property_id', data?.property_id)
  formdata.append('policy_name', data?.name)
  data?.booking_engin &&
    formdata.append('use_on_booking_engine', data?.booking_engin)
  data?.copy_price_text &&
    formdata.append('copy_price_to_next_year', data?.copy_price_text)
  data?.overwrite_current_price &&
    formdata.append('overwrite_current_price', data?.overwrite_current_price)
  formdata.append('price_per_person', false)
  data?.activate_minimum_booking_offset &&
    formdata.append('activate_minimum_booking_offset', data?.minBookingOffest)
  data?.collection_policy &&
    formdata.append('cancellation_policy', data?.collection_policy)
  data?.board && formdata.append('board_type', data?.board)
  data?.restriction_plan &&
    formdata.append('restriction_plan', data?.restriction_plan)
  data?.defaultValue &&
    formdata.append('default_price_plane', data?.defaultValue)

  return ApiPropertyService.fetchData({
    url: `policy/update-price-policy/${data?.record_id}/`,
    method: 'put',
    data: formdata,
    token: data.token,
    content_type: true
  })
}
//======================== apiEditDefaultPricePolicy=========================
export const apiEditDefaultPricePolicy = (data: any) => {
  let formdata = new FormData()
  formdata.append('default_price_plane', data?.defaultValue)
  return ApiPropertyService.fetchData({
    url: `policy/default-price-policy/${data?.record_id}/`,
    method: 'put',
    data: formdata,
    token: data.token
  })
}

//======================== apiAddLostAndFoundReport=========================
export const apiAddLostAndFoundReport = (data: any) => {
  const formdata = new FormData()
  formdata.append('name', data?.name)
  formdata.append('unit_info', data?.unit_info)
  formdata.append('room', data?.room)
  formdata.append('description', data?.description)
  formdata.append('property_id', data?.property_id)
  formdata.append('lost_or_found', data?.lost_or_found)
  formdata.append('image', data?.file)
  return ApiPropertyService.fetchData({
    url: 'report/create-lost-found-report/',
    method: 'post',
    data: formdata,
    token: data.token
  })
}

//======================== apiUpdateLostAndFoundReport =========================
export const apiUpdateLostAndFoundReport = (data: any) => {
  const formdata = new FormData()
  formdata.append('picked_up', data?.picked_up)
  return ApiPropertyService.fetchData({
    url: `report/lost-found-report/${data?.record_id}/`,
    method: 'PATCH',
    data: formdata,
    token: data.token
  })
}

//======================== apiAddMaintenanceReport =========================
export const apiAddMaintenanceReport = (data: any) => {
  const formdata = new FormData()
  formdata.append('name', data?.name)
  formdata.append('unit_info', data?.unit_info)
  formdata.append('room', data?.room)
  formdata.append('description', data?.description)
  formdata.append('property_id', data?.property_id)
  formdata.append('price', data?.price)
  formdata.append('image', data?.file)
  return ApiPropertyService.fetchData({
    url: 'report/create-maintenance-report/',
    method: 'post',
    data: formdata,
    token: data.token
  })
}

//======================== apiAddCalendarNotification =========================
export const apiAddCalendarNotification = (data: any) => {
  const formdata = new FormData()
  formdata.append('date', dayjs(new Date(data?.date)).format('YYYY-MM-DD'))
  formdata.append('title', data?.title)
  formdata.append('description', data?.description)
  formdata.append('property_id', data?.property_id)

  return ApiPropertyService.fetchData({
    url: 'calendar/add-notification/',
    method: 'post',
    data: formdata,
    token: data.token
  })
}

//======================== apiEditCalendarNotification =========================
export const apiEditCalendarNotification = (data: any) => {
  const formdata = new FormData()
  formdata.append('date', dayjs(new Date(data?.date)).format('YYYY-MM-DD'))
  formdata.append('title', data?.title)
  formdata.append('description', data?.description)

  return ApiPropertyService.fetchData({
    url: `calendar/update-notification/${data?.record_id}/`,
    method: 'put',
    data: formdata,
    token: data.token
  })
}

//======================== apiGeneratePasscode =========================
export const apiGeneratePasscode = (data: any) => {
  const formdata = new FormData()
  formdata.append('start_date', data?.start_date)
  formdata.append('end_date', data?.end_date)
  formdata.append('lock_id', data?.lock_id)
  formdata.append('passcode', data?.passcode)

  return ApiPropertyService.fetchData({
    url: 'room/add-custom-passcode/',
    method: 'post',
    data: formdata,
    token: data.token
  })
}

//======================== Revoke Ekey =========================
export const apiRevokeEkey = (data: any) => {
  const formdata = new FormData()
  formdata.append('lock_id', data?.lock_id)
  formdata.append('key_id', data?.key_id)

  return ApiPropertyService.fetchData({
    url: 'room/delete-ekey/',
    method: 'post',
    data: formdata,
    token: data.token
  })
}

//========================Revoke Passcode =========================
export const apiRevokePasscode = (data: any) => {
  const formdata = new FormData()
  formdata.append('lock_id', data?.lock_id)
  formdata.append('passcode_id', data?.passcode_id)
  return ApiPropertyService.fetchData({
    url: 'room/delete-passcode/',
    method: 'post',
    data: formdata,
    token: data.token
  })
}

//======================== apiUpdateCalendarSettingConfig =========================
export const apiUpdateCalendarSettingConfig = (data: any) => {
  const formdata = new FormData()
  formdata.append('calendar_type', data?.calendarType)
  formdata.append('number_of_days', data?.numberOfDays)
  formdata.append('row_height', data?.rowHeight)
  formdata.append('reservation_color', data?.reservationColor)
  formdata.append('weekends', data?.weekends)
  formdata.append('availability', data?.availability)
  formdata.append('price', data?.price)
  formdata.append('min_stay', data?.minStay)
  formdata.append('calendar_scrolling', data?.calendarScrolling)
  formdata.append('collapse_room_type', data?.collapseRoomType)
  formdata.append('display_on_full_field', data?.displayOnFullField)
  formdata.append('room_number', data?.roomNumber)
  formdata.append('room_type', data?.roomType)
  formdata.append('room_status', data?.roomStatus)
  formdata.append('autosave_changes', data?.autosaveChanges)

  return ApiPropertyService.fetchData({
    url: `calendar/update-calendar-setting/${data?.record_id}/`,
    method: 'put',
    data: formdata,
    token: data.token
  })
}

//======================== apiUpdateYeildCalendarVariation =========================
export const apiUpdateYeildCalendarVariation = (data: any) => {
  return ApiPropertyService.fetchData({
    url: 'calendar/update-variation/',
    method: 'put',
    data: JSON.stringify({
      variations: data?.data
    }),
    token: data.token,
    content_type: true
  })
}

//======================== full sync with channel api=========================
export const apiSyncWithChannel = (data: any) => {
  return ApiPropertyService.fetchData({
    url: `room/full-sync/?property=${data?.property_id}`,
    method: 'post',
    token: data.token
  })
}

//======================== full sync with channel api=========================
export const apiAutoUpdateAvilability = (data: any) => {
  return ApiPropertyService.fetchData({
    url: `policy/auto-set-availability/?property=${data?.id}`,
    method: 'post',
    token: data.token
  })
}
