/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 19/08/2024, 14:56:04
Modified: 19/08/2024, 17:29:33

Description:
  This file contains the `useLocalizeDocumentAttributes` hook, which updates the document's `lang` and `dir` attributes based on the current locale. It uses the `useTranslation` hook from `react-i18next` to get the direction of the current locale and `useLocale` to obtain the locale. The hook sets the `lang` and `dir` attributes on the `document.documentElement` to ensure proper localization and text direction in the document.
*/

import { useEffect } from 'react'

import useLocale from './useLocale'

const useLocalizeDocumentAttributes = () => {
  const { locale, direction } = useLocale()

  useEffect(() => {
    if (locale) {
      document.documentElement.lang = locale
      document.documentElement.dir = direction
    }
  }, [locale])
}

export default useLocalizeDocumentAttributes